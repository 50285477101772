<template>
  <!-- <div class="box" :style="styleBox">  -->
  <!-- <div :class="labelTop ? 'boxTop' : 'box'" :style="styleBox"> -->

  <div :class="boxClass">

    <div
      v-if="label"
      :class="labelTop ? 'labelTop' : 'label'"
      :style="styleLabel">
        {{ label }}
    </div>

    <div style="display:flex">
      <input
        v-bind="$attrs"
        :class="labelTop ? 'inputTop' : 'input'"
        :style="styleInput"
        :max="max"
        :min="min"
        :type="type"
         v-model="displayValue"

        @blur="lostFocus"
        @focus="isInputActive = true"
      />

      <span></span>

      <div
        v-if="icons.length"
        :class="labelTop ? 'iconsTop' : 'iconsSide'">
          <v-icon
            v-for="icon in icons" :key="icon.ctrl"
            :disabled="disabled"
            dense
            @click.native.capture="icon.fn(icon)">
              {{ icon.icon }}
          </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [String], default: "" },
    type: { type: [String], default: "date" },
    labelSide: { type: Boolean, default: false },
    label: { type: [String, Number], default: "" },

    styleBox: { type: String, default: "" },
    styleInput: { type: String, default: "" },
    styleLabel: { type: String, default: "" },

    max: { type: String, default: "2061-01-01" },
    min: { type: String, default: "1900-01-01" },

    icons: { type: Array, default: ()=> [] },

    // tipo: { type: String, default: "text" }
  },

  data() {
    return {
      isInputActive: false,
    };
  },

  methods: {
    datetime2local(d) {
      console.log("local:" + d + "*");
      if (this.type === "data") {
        if (d == "") this.$emit("input", "0000-00-00");
        if (d == "0000-00-00") return "";
        return d;
      }

      if (d == "") this.$emit("input", "0000-00-00 00:00");
      if (d == "0000-00-00 00:00") return "";
      return d.replace(" ", "T");
    },

    local2datetime(d) {
      console.log("date:", d.value);
      if (this.type === "data") {
        if (d.value === "") {
          this.$emit("input", "0000-00-00");
          return;
        }
        console.log("date:", d.value);
        this.$emit("input", d.value);
        return;
      }

      if (d.value == "") {
        this.$emit("input", "0000-00-00 00:00");
        return;
      }
      console.log("dateTime:", d);
      this.$emit("input", d.replace("T", " "));
      return;
    },
    lostFocus(event) {
      this.isInputActive = false;
      console.log("datetine", event.target.checkValidity());
      // .bind('invalid', function(event){
      //   setTimeout(function() { $(event.target).focus();}, 50);}
    },


  },


  computed: {

    displayValue: {
      get: function () {
        console.log("local:" + this.value+ "*");
        if (this.type === "date") {
          //if (this.value== "") this.$emit("input", "0000-00-00");
          if (this.value== "0000-00-00") return "";
          return this.value;
        }

        //if (this.value== "") this.$emit("input", "0000-00-00 00:00");
        if (this.value == "0000-00-00 00:00") return "";
        return this.value.replace(" ", "T").slice(0,16)
      },

      set: function (d) {
        console.log("date:", d);
        if (this.type === "date") {
          if (d == "") {
            this.$emit("input", "");
            return;
          }
          console.log("date:", d);
          this.$emit("input", d);
          return;
        }

        if (d== "") {
          this.$emit("input", "");
          return;
        }
        console.log("date:", d);
        this.$emit("input", d.replace("T", " ").slice(0,16));
        return;
      }
   },


   disabled() {
     if (!Object.keys(this.$attrs).length) return false;
     if (!Object.prototype.hasOwnProperty.call(this.$attrs, 'disabled')) return false;
     if (this.$attrs.disabled) return true;
     return false;
   },



    labelTop: function () {
      if (this.labelSide) return false;
      return true;
    },
    boxClass() {
      let clase = "";
      //  <div :class="labelTop ? 'boxTop' : 'box'" :style="styleBox">
      clase = this.labelTop ? "boxTop" : "box";
      clase += this.isInputActive ? " boxFocused" : "";
      return clase;
    },
  },
};
</script>

<style scoped>
.label {
  /* por defecto el label ocupa siempre su contenido */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 6px 2px 18px;

  font-size: 0.85rem;
  border-right: 1px solid white;
  border-radius: 18px 0 0 18px;
  background-color: rgb(176, 190, 197, 0.35);
  color: steelblue;
  user-select: none;
}

.labelTop {
  font-size: 0.85rem;
  color: steelblue;
  /* padding: 5px 12px 0 0; */
  padding-top: 5px;
  line-height: 10px;
  user-select: none;
}

.inputTop {
  flex: 1 1 100%;
  /* poner por defecto a los numéricos alineación izquierda? */
  /* text-align: right; */
  padding: 0px 12px 0 0;
  /* color: red; */
}
.styleBox {
  outline: 0;
  box-shadow: 0 0 1px blue;
}

.input {
  flex: 1 1 100%;
  /* text-align: right; */
  padding: 0px 12px 0 8px;
}
.box {
  flex: 1 1 auto;
  display: flex;
  height: 2rem;
  font-size: 1rem;
  border-radius: 18px;
  background-color: rgb(240,240,240);
  margin: 0 0px 4px 0;
}

.boxTop {
  height: 37.73px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  border-radius: 18px;
  background-color: rgb(240,240,240);
  margin: 0 0px 4px 0;
  padding: 0px 0px 0 18px;
}
.boxFocused {
  box-shadow: 0px 0px 3px steelblue;
}

.box .fieldError {
  /* background-color: lightblue; */
  /* outline:none; */
  /* border-radius: 0 ; */
  /* border: 1px solid steelblue; */
  /* outline: 2px solid red; */
  /* outline-style:inset; */

  color: white;
  background-color: lightcoral;
}

input:focus {
  outline: none;
}
input:invalid + span:before {
  /* https://www.kirupa.com/html5/emoji.htm */
  /* https://emojipedia.org/ */
  /* https://www.w3schools.com/charsets/ref_emoji.asp */
  /* https://stackoverflow.com/questions/32413731/color-for-unicode-emoji */
  /* content:'\01F354';  */
  content: "✖";
  color: red;
  margin-left: -15px;
}
/* input:valid+span:after {
    content: '✓';
    padding-left: 5px;
} */

.validity {
  color: red;
}


.iconsTop {
  position: relative;
  top:-5px;
  right: 12px;
  cursor: pointer;
}

</style>
